import { Divider, Modal, Space, TableProps } from 'antd'
import { formatCurrency } from '../../utils/string-utils'
import { useEffect, useState } from 'react'
import { apiGetListPackageDataSponsorsPartnerByParentId, apiGetPackageDataSponsor } from '../../api'
import { useLoading } from '../../context/LoadingContext'
import { formatDate } from '../../utils/entity-utils'
import CustomTable from '../../components/table'
import { TDM_SOURCE } from '../../assets/constants/default_option'

type FieldModalView = {
    modalView: { data: any | null, isOpen: boolean }
    setModalView: Function
}

export default function ViewPartnerSponsor({ modalView, setModalView }: FieldModalView) {
    const [pack, setPack] = useState<any>({})
    const [proPacks, setProPacks] = useState<any>([])
    const { setIsLoading } = useLoading()

    const fetchPack = async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetPackageDataSponsor({
                id: modalView.data?.packageId,
            })
            if (resp.data.package != null) {
                setPack(resp.data.package)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchProPacks = async () => {
        if (modalView.data?.parentId !== 0) {
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiGetListPackageDataSponsorsPartnerByParentId({
                parentId: modalView.data?.ID,
            })
            if (resp.data != null) {
                setProPacks(resp.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (modalView.data?.packageId) {
            fetchPack()
            fetchProPacks()
        } else {
            setPack({})
            setProPacks([])
        }
    }, [modalView])

    const sponsorColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: "STT",
            render: (value, recode, index) => ++index,
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'dataPack',
            title: 'Mã gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'discount',
            title: 'Discount',
            dataIndex: 'discount',
            render: discount => Number(discount).toFixed(2),
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'priceUnit',
            title: 'Đơn giá',
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit || 0)}
                </span>
            ),
        },
        {
            key: 'CreatedAt',
            title: "Thời gian tạo",
            dataIndex: 'CreatedAt',
            render: CreatedAt => formatDate(CreatedAt)
        },
    ]

    return (
        <Modal
            className='custom-modal-width'
            title='Chi tiết gói cước'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <Space>
                <h3 style={{ margin: 0 }}>Gói cước đối tác:</h3>
                <span>{modalView.data?.cpCode}</span>
            </Space>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <div>
                    <p><b>Tên gói: </b>{modalView.data?.name}</p>
                    <p><b>Giá bán: </b>{formatCurrency(modalView.data?.price)}</p>
                    <p><b>Thời gian tạo: </b>{formatDate(modalView.data?.CreatedAt)}</p>
                </div>
                <div>
                    <p><b>Mã gói: </b>{modalView.data?.dataPack}</p>
                    <p><b>Discount: </b>{modalView.data?.discount}</p>
                    <p><b>Cập nhật: </b>{formatDate(modalView.data?.UpdatedAt)}</p>
                </div>
                <div>
                    <p><b>Đơn giá: </b>{formatCurrency(modalView.data?.priceUnit)}</p>
                    <p><b>Trạng thái: </b>{modalView.data?.status}</p>
                </div>
            </div>
            <span><b>Mô tả: </b>{modalView.data?.description}</span>
            <Divider />
            <h3 style={{ margin: 0 }}>Gói cước gốc</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <div>
                    <p><b>Tên gói: </b>{pack.name}</p>
                    <p><b>Phạm vi: </b>{pack.scope}</p>
                    <p><b>Thời gian tạo: </b>{formatDate(pack.CreatedAt)}</p>
                </div>
                <div>
                    <p><b>Mã gói: </b>{pack.dataPack}</p>
                    <p><b>Loại: </b>{pack.type}</p>
                    <p><b>Cập nhật: </b>{formatDate(pack.UpdatedAt)}</p>
                </div>
                <div>
                    <p><b>Nguồn: </b>{pack?.source}</p>
                    <p><b>Giá: </b>{formatCurrency(pack.priceUnit)}</p>
                    <p><b>Trạng thái: </b>{pack.status}</p>
                </div>
            </div>
            <span><b>Mô tả: </b>{pack.description}</span>
            <Divider />
            {pack?.source !== TDM_SOURCE ? (
                <CustomTable
                    namePage='gói khuyến mãi'
                    columns={sponsorColumns}
                    dataSource={proPacks?.packages}
                    total={proPacks?.total}
                />
            ) : (
                <p><b>Gói cước áp dụng: </b>{modalView.data?.parentId}</p>
            )}
        </Modal>
    )
}
