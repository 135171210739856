import { useCallback, useEffect, useState } from "react"
import { apiGetAccountDataSponsorCurrent, apiGetProviderCurrent, apiGetStatusMaintain } from "../../api"
import { Button, Card, Tag } from "antd"
import { WHILELIST_ACCOUNT } from "../../config/constants"
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import ModalMaintain from "./ModalMaintain";
import ModalAccount from "./ModalAccount";
import ModalProvider from "./ModalProvider";

export default function SystemPage() {
    const [canUpdate, setCanUpdate] = useState(false)
    const authState = useSelector(authSelector);
    const { account } = authState;
    const [maintainValue, setMaintainValue] = useState('');
    const [accountValue, setAccountValue] = useState('');
    const [providerValue, setProviderValue] = useState('');
    const [modalMaintain, setModalMaintain] = useState(false);
    const [modalAccount, setModalAccount] = useState(false);
    const [modalProvider, setModalProvider] = useState(false);

    const fetchMaintain = useCallback(async () => {
        try {
            const resp = await apiGetStatusMaintain()
            if (resp.data?.value !== null) {
                setMaintainValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchAccount = useCallback(async () => {
        try {
            const resp = await apiGetAccountDataSponsorCurrent()
            if (resp.data?.value !== null) {
                setAccountValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchProvider = useCallback(async () => {
        try {
            const resp = await apiGetProviderCurrent()
            if (resp.data?.value !== null) {
                setProviderValue(resp.data.value)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const checkWhilelistAccount = useCallback(() => {
        const listWhilelistAccounts = WHILELIST_ACCOUNT?.split(',')
        if (listWhilelistAccounts?.includes(account.username)) {
            setCanUpdate(true)
        }
    }, [])

    useEffect(() => {
        checkWhilelistAccount()
        fetchMaintain()
        fetchAccount()
        fetchProvider()
    }, [])

    const renderTag = (value: string, isActive = true) => (
        <Tag color={isActive ? "green" : "red"}>
            <h3 style={{ margin: 0, padding: 4 }}>{value}</h3>
        </Tag>
    )

    const renderMaintain = () => {
        if (maintainValue === 'true') {
            return renderTag('Đang bảo trì', false)
        }
        return renderTag('Đang hoạt động')
    }

    const renderAccount = () => {
        switch (accountValue) {
            case 'data_sponsor':
                return renderTag('SANN')
            case 'datasponsor_zynet':
                return renderTag('Zynet')
            default:
                return renderTag('Không tìm thấy', false)
        }
    }

    const renderProvider = () => {
        if (providerValue === 'ds_postpaid') {
            return renderTag('Trả sau')
        }
        return renderTag('Trả trước')
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20, flexWrap: 'wrap', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <Card title="Trạng thái hệ thống" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderMaintain()}
                            {canUpdate && <Button onClick={() => setModalMaintain(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Tài khoản Viettel đang hoạt động" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderAccount()}
                            {canUpdate && <Button onClick={() => setModalAccount(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <Card title="Nhà cung cấp data sponsor hiện tại" bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {renderProvider()}
                            {canUpdate && <Button onClick={() => setModalProvider(true)} type="primary">Cập nhật</Button>}
                        </div>
                    </Card>
                </div>
            </div>
            <ModalMaintain
                isOpen={modalMaintain}
                setIsOpen={setModalMaintain}
                data={maintainValue}
                fetchData={fetchMaintain}
            />
            <ModalAccount
                isOpen={modalAccount}
                setIsOpen={setModalAccount}
                data={accountValue}
                fetchData={fetchAccount}
            />
            <ModalProvider
                isOpen={modalProvider}
                setIsOpen={setModalProvider}
                data={providerValue}
                fetchData={fetchProvider}
            />
        </div>
    )
}
