import { useEffect, useState } from 'react'
import { Button, Space, TableProps, Tabs, TabsProps } from 'antd'
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import CustomTable from '../../components/table'
import { formatCurrency } from '../../utils/string-utils'
import { Link } from 'react-router-dom'
import { CREATE_PARTNET_SPONSOR, CREATE_PARTNET_SPONSOR_PROMOTION, DETAIL, EDIT_PARTNET_SPONSOR, EDIT_PARTNET_SPONSOR_PROMOTION, PACKAGES } from '../../assets/constants/route_path'
import ViewPartnerSponsor from './ViewPartnerSponsor'
import { formatDate } from '../../utils/entity-utils'

export default function TabPartnerSponsor({
    sponsors,
    checkPartnerDataSponsor,
    clearEventSponsor,
    customers,
    selectedCustomerDataSponsor,
    setSelectedCustomerDataSponsor,
    selectSponsorRef
}: any) {
    const [listSponsors, setListSponsors] = useState([])
    const [listPromotions, setListPromotions] = useState([])
    const [listSponsorOptions, setListSponsorOptions] = useState([])
    const [searchPackageKey, setSearchPackageKey] = useState('')
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })

    const sponsorColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: "STT",
            render: (value, recode, index) => ++index,
        },
        {
            key: 'cpCode',
            title: 'CpCode',
            dataIndex: 'cpCode',
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'dataPack',
            title: 'Mã gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'discount',
            title: 'Discount',
            dataIndex: 'discount',
            render: discount => Number(discount).toFixed(2),
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'priceUnit',
            title: 'Đơn giá',
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit || 0)}
                </span>
            ),
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
        },
        {
            key: 'CreatedAt',
            title: "Thời gian tạo",
            dataIndex: 'CreatedAt',
            render: CreatedAt => formatDate(CreatedAt)
        },
        {
            key: 'action',
            render: data => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    <Link to={PACKAGES + DETAIL + EDIT_PARTNET_SPONSOR + `?cpCode=${data.cpCode}&dataPack=${data.dataPack}`}>
                        <Button
                            onClick={() => setModalView({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            style={{ background: '#ae7700', color: 'white' }}
                        >
                            Cập nhật
                        </Button>
                    </Link>
                </Space>
            )
        }
    ]

    const promotionColumns: TableProps<any>['columns'] = [
        {
            key: 'STT',
            title: "STT",
            render: (value, recode, index) => ++index,
        },
        {
            key: 'cpCode',
            title: 'CpCode',
            dataIndex: 'cpCode',
        },
        {
            key: 'parentId',
            title: 'Gói cước áp dụng',
            dataIndex: 'parentId',
        },
        {
            key: 'name',
            title: 'Tên gói cước',
            dataIndex: 'name',
        },
        {
            key: 'dataPack',
            title: 'Mã gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'discount',
            title: 'Discount',
            dataIndex: 'discount',
            render: discount => Number(discount).toFixed(2),
        },
        {
            key: 'price',
            title: 'Giá',
            dataIndex: 'price',
            render: price => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(price)}
                </span>
            ),
        },
        {
            key: 'priceUnit',
            title: 'Đơn giá',
            dataIndex: 'priceUnit',
            render: priceUnit => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {formatCurrency(priceUnit || 0)}
                </span>
            ),
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'status',
        },
        {
            key: 'CreatedAt',
            title: "Thời gian tạo",
            dataIndex: 'CreatedAt',
            render: CreatedAt => formatDate(CreatedAt)
        },
        {
            key: 'action',
            render: data => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    <Link to={PACKAGES + DETAIL + EDIT_PARTNET_SPONSOR_PROMOTION + `?cpCode=${data.cpCode}&dataPack=${data.dataPack}`}>
                        <Button
                            onClick={() => setModalView({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            style={{ background: '#ae7700', color: 'white' }}
                        >
                            Cập nhật
                        </Button>
                    </Link>
                </Space>
            )
        }
    ]

    useEffect(() => {
        const listPackages = sponsors?.packages
        const listPromotions = [] as any
        const listSponsorOptions = [] as any
        const listSponsors = listPackages?.filter((item: any) => {
            if (item.parentId === 0) {
                listSponsorOptions.push({ label: item.name, value: item.name })
                return true
            }
            for (let i = 0; i < listPackages?.length; i++) {
                if (listPackages[i].ID === item.parentId) {
                    item.parentId = listPackages[i].name
                    break
                }
            }
            listPromotions.push(item)
            return false
        })
        setListSponsors(listSponsors)
        setListPromotions(listPromotions)
        setListSponsorOptions(listSponsorOptions)
    }, [sponsors?.packages])

    const itemsTab: TabsProps['items'] = [
        {
            key: 'listSponsors',
            label: 'Gói cước',
            children: <CustomTable
                namePage='gói cước'
                columns={sponsorColumns}
                dataSource={listSponsors}
                total={listSponsors?.length || 0}
            />,
        },
        {
            key: 'listPromotions',
            label: 'Gói khuyến mãi',
            children:
                <>
                    <Space style={{ marginBottom: 20 }}>
                        <Select
                            value={searchPackageKey}
                            onChange={value => setSearchPackageKey(value)}
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn gói cước"
                            filterOption={(input: string, option: any) => option.label.toLowerCase().includes(input.toLowerCase())}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...listSponsorOptions,
                            ]}
                        />
                        <Button onClick={() => setSearchPackageKey('')}>Clear</Button>
                    </Space>
                    <CustomTable
                        namePage='gói khuyến mãi'
                        columns={promotionColumns}
                        dataSource={searchPackageKey ? listPromotions.filter((item: any) => item.parentId === searchPackageKey) : listPromotions}
                        total={searchPackageKey ? listPromotions.filter((item: any) => item.parentId === searchPackageKey).length : listPromotions?.length}
                    />
                </>,
        },
    ]

    return (
        <div style={{ overflow: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={{ display: 'flex', marginBottom: 10, marginRight: 30, alignItems: 'baseline' }}>
                    <span style={{ width: 80 }}>Chọn đối tác</span>
                    <Select
                        ref={selectSponsorRef}
                        showSearch
                        style={{ flex: 1, marginBottom: 16, minWidth: '300px' }}
                        placeholder="Chọn khách hàng"
                        optionFilterProp="name"
                        onChange={(value) => setSelectedCustomerDataSponsor(value)}
                        filterOption={(input: any, option: any) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedCustomerDataSponsor}
                    >
                        {customers?.partners?.map((customer: any, idx: React.Key) => (
                            <Select.Option key={idx} value={idx}>
                                {customer.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <Button type="primary" block style={{ width: 100, marginRight: 10 }} onClick={() => checkPartnerDataSponsor()}>Tìm kiếm</Button>
                <Button danger block style={{ width: 100 }} onClick={() => clearEventSponsor()}>Clear</Button>
            </div>
            <div style={{ textAlign: 'end', marginBottom: 20 }}>
                <Space>
                    <Link to={PACKAGES + DETAIL + CREATE_PARTNET_SPONSOR + `?cpCode=${customers.partners[Number(selectedCustomerDataSponsor)]?.cpCode}`}>
                        <Button type='primary'>Thêm gói cước</Button>
                    </Link>
                    <Link to={PACKAGES + DETAIL + CREATE_PARTNET_SPONSOR_PROMOTION + `?cpCode=${customers.partners[Number(selectedCustomerDataSponsor)]?.cpCode}`}>
                        <Button type='dashed'>Thêm gói khuyến mãi</Button>
                    </Link>
                </Space>
            </div>
            <Tabs defaultActiveKey={'listSponsors'} items={itemsTab} />
            <ViewPartnerSponsor
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}
